@media screen and (min-width: 200px) {
  %flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  %flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  %flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  %flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  %flex-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  %flex-center-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  %flex-start-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  %flex-end-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  %flex-start-end {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  %back-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  %transition {
    transition: 0.2s ease-in-out;
  }
}
