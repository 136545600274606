.Reuse-dreches-wrap {
  margin: 80px auto;
  width: 800px;
  @extend %flex-center-center;
  position: relative;
  height: 740px;
}

.drechesIcon {
  position: absolute;
  transform: scale(1);
  transition: 0.2s ease-in-out transform;
  width: 200px;
  z-index: 2;
  background-color: $red;
}

.drechesIcon.activeIcon {
  transform: scale(1.5);
  transition: 0.2s ease-in-out transform;
}

.drechesArrow {
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
}
.drechesArrowRight:nth-child(2) {
  top: 0px;
}
.drechesArrowRight:nth-child(10) {
  bottom: 0px;
}
.drechesArrowBackLeft {
  right: 0px;
  top: 0px;
  position: absolute;
}
.drechesArrowBackRight {
  left: 0px;
  bottom: 0px;
  position: absolute;
}

.Reuse-dreches-wrap {
  width: 100%;
  max-width: 530px;
  height: 448px;
}

.Reuse-dreches-wrap {
  width: 100%;
  max-width: 815.38px;
  height: 689.23px;
}

.drechesArrow {
  width: 107.69px;
}

.drechesArrowBackLeft,
.drechesArrowBackRight {
  width: 67.69px;
}

#drechesGraine {
  left: 120px;
  top: -53.85px;
  width: 169.23px;
}

#drechesConcassage {
  right: 130.77px;
  top: -84.62px;
  width: 138.46px;
}

#drechesBrassage {
  right: 143.08px;
  top: 50%;
  width: 107.69px;
  transform: translateY(-50%) scale(1);
}

#drechesBrassage.activeIcon {
  transform: translateY(-50%) scale(1.5);
}

#drechesFiltre {
  left: 135.38px;
  top: 50%;
  width: 138.46px;
  transform: translateY(-50%) scale(1);
}

#drechesFiltre.activeIcon {
  transform: translateY(-50%) scale(1.5);
}

#drechesTransport {
  left: 92.31px;
  bottom: -84.62px;
  width: 246.15px;
}

#drechesAjout {
  right: 98.46px;
  bottom: -53.85px;
  width: 215.38px;
}



@media screen and (max-width: 1500px) {
  .Reuse-dreches-wrap {
    width: 100%;
    max-width: 530px;
    height: 448px;
  }

  .drechesArrow {
    width: 70px;
  }
  .drechesArrowBackLeft,
  .drechesArrowBackRight {
    width: 44px;
  }

  #drechesGraine {
    left: 78px;
    top: -35px;
    width: 110px;
  }
  #drechesConcassage {
    right: 85px;
    top: -55px;
    width: 90px;
  }
  #drechesBrassage {
    right: 93px;
    top: 50%;
    width: 70px;
    transform: translateY(-50%) scale(1);
    &.activeIcon {
      transform: translateY(-50%) scale(1.5);
    }
  }
  #drechesFiltre {
    left: 88px;
    top: 50%;
    width: 90px;
    transform: translateY(-50%) scale(1);
    &.activeIcon {
      transform: translateY(-50%) scale(1.5);
    }
  }
  #drechesTransport {
    left: 60px;
    bottom: -55px;
    width: 160px;
  }
  #drechesAjout {
    right: 64px;
    bottom: -35px;
    width: 140px;
  }
}

@media screen and (max-width: 767px) {
  .Reuse-dreches-wrap {
    width: 100%;
    max-width: 350px;
    height: 350px;
  }

  .drechesIcon {
    width: 50px;
  }

  .drechesArrow {
    width: 50px;
  }
  .drechesArrowBackLeft,
  .drechesArrowBackRight {
    width: 35px;
  }

  #drechesGraine {
    left: 41px;
    top: -35px;
    width: 85px;
  }
  #drechesConcassage {
    right: 49px;
    top: -55px;
    width: 65px;
  }
  #drechesBrassage {
    right: 62px;
    top: 50%;
    width: 50px;
    transform: translateY(-50%) scale(1);
    &.activeIcon {
      transform: translateY(-50%) scale(1.5);
    }
  }
  #drechesFiltre {
    left: 59px;
    top: 50%;
    width: 60px;
    transform: translateY(-50%) scale(1);
    &.activeIcon {
      transform: translateY(-50%) scale(1.5);
    }
  }
  #drechesTransport {
    left: 44px;
    bottom: -35px;
    width: 88px;
  }
  #drechesAjout {
    right: 31px;
    bottom: -35px;
    width: 85px;
  }
}
