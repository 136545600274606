// PRIMARY COLORS
$white: #FFFFFF;
$red: #cc221d;
$yellow: #d6bc78;
$blackred: #860101;

// SECONDARY COLORS

$blackPlaceholder: #9b0600;
$text-shadow: 0px 4px 20px  rgba(0,0,0,0.58);
