.Reuse {
  @extend %flex-start-center;
  flex-direction: column;
  width: 1321px;
  margin-top: 200px;
}

.Reuse-title {
  text-align: center;
  margin-bottom: 110px;
}

.Reuse-infographie-wrap {
  margin: 80px auto;
  width: 800px;
  @extend %flex-center-center;
  position: relative;
  height: 740px;
}

#infographieCircleArrow {
  width: 300px;
  height: 300px;
}

.infographieIcon {
  position: absolute;
  transform: scale(1);
  transition: 0.2s ease-in-out transform;
  width: 200px;
  z-index: 2;
  background-color: $red;
}

.infographieIcon.centerIcon {
  transform: translate(-50%, 0%) scale(1);
}

.infographieIcon.activeIcon {
  transform: scale(1.5);
  transition: 0.2s ease-in-out transform;
}
.infographieIcon.activeIcon.centerIcon {
  transform: translate(-50%, 0%) scale(1.5);
  transition: 0.2s ease-in-out transform;
}

#infographieProduction {
  top: -38px;
  left: 50%;
  width: 180px;
}

#infographieLivraison {
  top: 18%;
  right: 51px;
  width: 106px;
}

#infographieConsommation {
  bottom: 15%;
  right: 70px;
  width: 100px;
}

#infographieCollecte {
  bottom: -40px;
  left: 50%;
  width: 116px;
}

#infographieVerification {
  bottom: 19%;
  left: 0px;
  width: 175px;
}

#infographieLavage {
  top: 20%;
  left: 0px;
  width: 160px;
}

.infographieTrait {
  position: absolute;
  z-index: 1;
}
#infographieTopRightTrait {
  top: 54px;
  right: 162px;
  width: 109px;
}
#infographieRightTrait {
  top: 311px;
  right: 80px;
  height: 100px;
}
#infographieBottomRightTrait {
  bottom: 86px;
  right: 189px;
  width: 121px;
  transform: rotate(-11deg);
}
#infographieBottomLeftTrait {
  bottom: 75px;
  left: 182px;
  width: 124px;
}
#infographieLeftTrait {
  top: 323px;
  left: 69px;
  height: 110px;
}
#infographieTopLeftTrait {
  top: 40px;
  width: 120px;
  left: 137px;
  transform: rotate(6deg);
}

.Reuse-stats {
  @extend %flex-center-center;
  width: 100%;
  margin-bottom: 70px;
  margin-top: 90px;
}

.Reuse-stat {
  width: 35%;
  text-align: center;
}

.Reuse-stat-little {
  line-height: 22px;
}

.Reuse-creation {
  text-align: center;
}

.Reuse-creation-big {
  line-height: 70px;
}

@media screen and (max-width: 1500px) {
  .Reuse {
    width: 1000px;
    margin-top: 150px;
  }

  .Reuse-infographie-wrap {
    width: 600px;
    height: 555px;
  }
  
  #infographieCircleArrow {
    width: 225px;
    height: 225px;
  }
  
  .infographieIcon {
    width: 150px;
  }
  
  #infographieProduction {
    width: 145px;
    top: -40px;
  }
  
  #infographieLivraison {
    top: 19%;
    width: 76px;
    right: 38px;
  }
  
  #infographieConsommation {
    bottom: 16%;
    width: 72px;
    right: 47px;
  }
  
  #infographieCollecte {
    width: 85px;
    bottom: -35px;
  }
  
  #infographieVerification {
    bottom: 19%;
    width: 142px;
  }
  
  #infographieLavage {
    top: 20%;
    width: 130px;
  }
  
  #infographieTopRightTrait {
    top: 40px;
    right: 114px;
    width: 90px;
  }
  #infographieRightTrait {
    top: 229px;
    right: 60px;
    height: 75px;
  }
  #infographieBottomRightTrait {
    bottom: 63px;
    right: 142px;
    width: 91px;
    transform: rotate(-11deg);
  }
  #infographieBottomLeftTrait {
    bottom: 56px;
    left: 136px;
    width: 93px;
  }
  #infographieLeftTrait {
    top: 242px;
    left: 52px;
    height: 82px;
  }
  #infographieTopLeftTrait {
    top: 30px;
    width: 91px;
    left: 103px;
    transform: rotate(6deg);
  }
  
  .Reuse-stats {
    margin-bottom: 0px;
    margin-top: 50px;
  }
  
  .Reuse-stat {
    width: 36%;
  }

  .Reuse-creation-big {
    line-height: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .Reuse {
    width: 700px;
  }
  
  .Reuse-stat {
    width: 42%;
  }

  .Reuse-creation-big {
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .Reuse {
    width: 90vw;
    margin-top: 75px;
  }
  
  .Reuse-title {
    width: 100%;
    margin-bottom: 50px;
  }

  .Reuse-infographie-wrap {
    width: 300px;
    height: 277px;
  }
  
  #infographieCircleArrow {
    width: 112px;
    height: 112px;
  }
  
  .infographieIcon {
    width: 75px;
  }
  
  .infographieIcon.activeIcon {
    transform: scale(1.5);
  }
  .infographieIcon.activeIcon.centerIcon {
    transform: translate(-55%, 0%) scale(1.5);
  }
  
  #infographieProduction {
    width: 75px;
    top: -20px;
  }
  
  #infographieLivraison {
    top: 20%;
    width: 35px;
    right: 20px;
  }
  
  #infographieConsommation {
    bottom: 14%;
    width: 38px;
    right: 21px;
  }
  
  #infographieCollecte {
    width: 49px;
    bottom: -27px;
  }
  
  #infographieVerification {
    bottom: 17%;
    width: 72px;
  }
  
  #infographieLavage {
    top: 20%;
    width: 60px;
  }
  
  #infographieTopRightTrait {
    top: 20px;
    right: 57px;
    width: 45px;
  }
  #infographieRightTrait {
    top: 114px;
    right: 30px;
    height: 37px;
  }
  #infographieBottomRightTrait {
    bottom: 31px;
    right: 71px;
    width: 45px;
    transform: rotate(-11deg);
  }
  #infographieBottomLeftTrait {
    bottom: 28px;
    left: 68px;
    width: 46px;
  }
  #infographieLeftTrait {
    top: 121px;
    left: 26px;
    height: 41px;
  }
  #infographieTopLeftTrait {
    top: 15px;
    width: 45px;
    left: 51px;
    transform: rotate(6deg);
  }
  
  .Reuse-stats {
    flex-direction: column;
  }
  
  .Reuse-stat {
    width: 55%;
    text-align: center;
    margin: 20px 0px;
  }

  .Reuse-creation-big {
    line-height: 40px;
  }
}
