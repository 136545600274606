@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Proxima-nova = Mulish

body {
    font-family: "Mulish", Courier, monospace;
    color: white;
}

.yellow {
    color: $yellow;
}

.blackred {
    color: $blackred;
}

.text-shadow {
  text-shadow: $text-shadow;
}

h1,h2,h3,h4,h5,h6 {
  text-shadow: $text-shadow;
}
body h2 {
  font-size: 80px;
}

.w900 {
  font-weight: 900;
}

.Menu-title-font {
    font-size: 80px;
    letter-spacing: 5px;
}

.Mood-title-font {
    font-size: 100px;
    letter-spacing: -2px;
}

.ADN-video-title {
  font-size: 40px;
}

.Bieres-slide-abstraite-title {
  font-size: 38px;
}
.Bieres-slide-abstraite-desc {
  font-size: 24px;
  line-height: 35px;
}

.FunZone-left-title {
  font-size: 50px;
}
.FunZone-left-desc, .FunZone-right-title {
  font-size: 35px;
}
.FunZone-right-submit-button {
  font-size: 33px;
}
.FunZone-right-submit-button-rules {
  font-size: 20px;
}

.Histoires-histoire-date {
  font-size: 115px;
}
.Histoires-histoire-description {
  text-align: justify;
  line-height: 28px;
}

.Engaged-P {
  font-size: 26px;
  line-height: 42px;
}

h2.Reuse-title-font {
  font-size: 60px;
  line-height: 75px;
}
.Reuse-stat-little, .Reuse-creation-little {
  letter-spacing: 2px;
  font-size: 20px;
}
.Reuse-stat-big, .Reuse-creation-big {
  font-size: 40px;
  letter-spacing: 0px;
}

.Filter-slogan {
  font-size: 40px;
}
.Filter-input {
  font-size: 228px;
}
.Filter-indication {
  font-size: 30px;
}
.Filter-submit-button {
  font-size: 38px;
}

@media screen and (max-width: 2000px) {
  body h2 {
    font-size: 75px;
  }
  h2.Reuse-title-font {
    font-size: 60px;
    line-height: 70px;
  }

  .Mood-title-font {
      font-size: 90px;
  }

  .Histoires-histoire-date {
    font-size: 88px;
  }
  .Histoires-histoire-description {
    line-height: 25px;
  }

  .Filter-slogan {
    font-size: 30px;
  }
  .Filter-input {
    font-size: 228px;
  }
  .Filter-indication {
    font-size: 25px;
  }
  .Filter-submit-button {
    font-size: 30px;
  }
}

@media screen and (max-width: 1900px) {
  body h2 {
    font-size: 70px;
  }
  h2.Reuse-title-font {
    font-size: 60px;
    line-height: 70px;
  }

  .ADN-video-title {
    font-size: 35px;
  }
}

@media screen and (max-width: 1800px) {
    .Menu-title-font {
        font-size: 50px;
        letter-spacing: 4px;
    }
}

@media screen and (max-width: 1600px) {
  body h2 {
    font-size: 60px;
  }
  h2.Reuse-title-font {
    font-size: 50px;
    line-height: 60px;
  }

  .Mood-title-font {
    font-size: 70px;
  }

  .Bieres-slide-abstraite-title {
    font-size: 27px;
  }
  .Bieres-slide-abstraite-desc {
    font-size: 17px;
    line-height: 24px;
  }

  .FunZone-left-title {
    font-size: 34px;
  }
  .FunZone-left-desc, .FunZone-right-title {
    font-size: 23px;
  }
  .FunZone-right-submit-button {
    font-size: 24px;
  }
  .FunZone-right-submit-button-rules {
    font-size: 18px;
  }
  
}

@media screen and (max-width: 1500px) {
  body h2 {
    font-size: 55px;
  }
  h2.Reuse-title-font {
    font-size: 45px;
    line-height: 55px;
  }

  .ADN-video-title {
    font-size: 25px;
  }

  .Histoires-histoire-date {
    font-size: 68px;
  }
  .Histoires-histoire-description {
    line-height: 25px;
  }

  .Engaged-P {
    font-size: 23px;
    line-height: 35px;
  }

  .Reuse-stat-little, .Reuse-creation-little {
    letter-spacing: 2px;
    font-size: 18px;
  }
  .Reuse-stat-big, .Reuse-creation-big {
    font-size: 34px;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 1400px) {
  .Filter-slogan {
    font-size: 25px;
  }
  .Filter-input {
    font-size: 136px;
  }
  .Filter-indication {
    font-size: 22px;
  }
  .Filter-submit-button {
    font-size: 30px;
  }
}

@media screen and (max-width: 1200px) {
  body h2 {
    font-size: 50px;
  }
  h2.Reuse-title-font {
    font-size: 32px;
    line-height: 41px;
  }

  .Mood-title-font {
      font-size: 50px;
  }

  .ADN-video-title {
    font-size: 30px;
  }

  .Bieres-slide-abstraite-title {
    font-size: 20px;
  }
  .Bieres-slide-abstraite-desc {
    font-size: 14px;
    line-height: 16px;
  }

  .Reuse-stat-little, .Reuse-creation-little {
    letter-spacing: 1px;
    font-size: 16px;
  }
  .Reuse-stat-big, .Reuse-creation-big {
    font-size: 28px;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 1100px) {
  h2.Reuse-title-font {
    font-size: 30px;
    line-height: 40px;
  }

  .BieresMobile-biere-title-font {
    font-size: 50px;
  }
  .BieresMobile-biere-description-font {
    font-size: 20px;
    line-height: 30px;
  }
  .BieresMobile-biere-description-font.yellow {
    text-decoration: underline;
  }

  .FunZone-right-submit-button {
    font-size: 30px;
  }
  .FunZone-right-submit-button-note {
    font-size: 14px;
  }
  .FunZone-left-desc {
    font-size: 24px;
  }
  .FunZone-right-title {
    font-size: 26px;
  }
  .FunZone-left-title {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  body h2 {
    font-size: 25px;
    text-align: center;
  }
  h2.Reuse-title-font {
    font-size: 20px;
    line-height: 30px;
    width: 90vw;
  }

  .Menu-title-font {
      font-size: 30px;
      letter-spacing: 3px;
  }

  .Mood-title-font {
      font-size: 27px;
  }

  .ADN-video-title {
    font-size: 20px;
  }

  .Bieres-slide-abstraite-title {
    font-size: 20px;
  }
  .Bieres-slide-abstraite-desc {
    font-size: 14px;
    line-height: 16px;
  }

  .FunZone-right-submit-button {
    font-size: 21px;
  }
  .FunZone-right-submit-button-note {
    font-size: 12px;
  }
  .FunZone-left-desc {
    font-size: 15px;
  }
  .FunZone-right-title {
    font-size: 21px;
  }
  .FunZone-left-title {
    font-size: 32px;
  }
  .FunZone-right-submit-button-rules {
    font-size: 14px;
  }

  .Histoires-histoireMobile-date {
    font-size: 50px;
    text-shadow: 0px 4px 20px  rgba(0,0,0,0.58);
  }
  .Histoires-histoireMobile-description {
    text-align: center;
    line-height: 20px;
  }

  .Engaged-P {
    font-size: 19px;
    line-height: 29px;
  }

  .Filter-slogan {
    font-size: 18px;
  }
  .Filter-input {
    font-size: 54px;
  }
  .Filter-indication {
    font-size: 16px;
  }
  .Filter-submit-button {
    font-size: 26px;
  }
}
/* 
@media screen and (max-width: 767px) {
  .BieresMobile-biere-title-font {
    font-size: 25px;
  }
} */
