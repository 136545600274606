.Warning {
  background-color: $blackred;
  width: 100vw;
  margin-top: 1%;
  max-width: 100%;
  @extend %flex-center-center;
  position: fixed;
  bottom: 0px;
  z-index: 4;
}

.Warning > p {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  line-height: 26px;
  padding: 23px 0px;
  font-size: 16px;
}

@media screen and (max-width: 767px) {

  .Warning > p {
    line-height: 16px;
    padding: 17px 0px;
    font-size: 11px;
  }
}
