.ADN {
  margin-top: 300px;
  @extend %flex-between-center;
  height: 679px;
  flex-direction: column;
}

.ADN-videos {
  @extend %flex-between-center;
  width: 1700px;

  &.videosFormat2 {
    width: 1300px;
  }
}

.ADN-video {
  @extend %flex-between-center;
  flex-direction: column;
  height: 374px;
  width: 30%;

  &.videosFormat2 {
    width: 40%;
  }
}

.ADN-video-iframeWrap {
  width: 100%;

  iframe, video {
    height: 236px;
    width: 100%;
  }
}

.ADN-video-player {
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.ADN-video-player-placeholder {
  width: 100%;
  position: relative;
  z-index: 2; 
  height: 236px;
}

.ADN-video-player-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 80px;
}

.ADN-video-player-square {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 0;
  pointer-events: none;
}
.ADN-video-player-square-left {
  width: 200px;
  height: 200px;
  left: -20px;
}
.ADN-video-player-square-right {
  width: 183px;
  height: 280px;
  right: -20px;
  top: -20px;
}
.ADN-video:nth-child(1) > .ADN-video-player > .ADN-video-player-square-left {
  bottom: -20px;
}
.ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-right {
  right: 50%;
  top: 57px;
  transform: translateX(50%) rotate(90deg);
}
.ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-left {
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.ADN-video:last-child > .ADN-video-player > .ADN-video-player-square-left {
  top: -20px;
}

@media screen and (max-width: 1900px) {
  .ADN {
    height: 585px;
  }
  
  .ADN-videos {
    width: 1400px;
  }
  
  .ADN-video {
    height: 356px;
  }
  
  .ADN-video-player-icon {
    width: 80px;
    height: 80px;
  }
  
  .ADN-video-player-square-left {
    width: 150px;
    height: 150px;
  }
  .ADN-video-player-square-right {
    height: 278px;
    width: 142px;
  }
  .ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-right {
    top: 50px;
  }
}

@media screen and (max-width: 1500px) {
  .ADN {
    height: 838px;

    &.videosFormat2 {
      height: 480px;
    }
  }
  
  .ADN-videos {
    width: 1020px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &.videosFormat2 {
      justify-content: space-between;
      width: 80vw;
    }
  }
  
  .ADN-video {
    height: 311px;
    width: 43%;

    &.videosFormat2 {
      width: 465px;
    }
  }

  .ADN-video:first-child {
    margin-right: 142px;
  }
  .ADN-video.videosFormat3:first-child, .ADN-video.videosFormat3:nth-child(2) {
    margin-bottom: 70px;
  }
  
  .ADN-video-player-square-left {
    width: 143px;
    height: 143px;
  }
  .ADN-video-player-square-right {
    width: 172px;
    height: 290px;
  }
  .ADN-video:nth-child(1) > .ADN-video-player > .ADN-video-player-square-left {
    bottom: -20px;
  }
  .ADN-video:last-child > .ADN-video-player > .ADN-video-player-square-right {
    right: 50%;
    top: 30px;
    transform: translateX(50%) rotate(90deg);
  }
  .ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-right {
    top: 54px;
    transform: none;
    right: -20px;
    top: -20px;
  }
  .ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-left {
    top: -20px;
    left: -20px;
    transform: none;
  }
  .ADN-video:last-child > .ADN-video-player > .ADN-video-player-square-left {
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 1200px) {
  .ADN {
    height: 844px;
    margin-top: 179px;

    &.videosFormat2 {
      height: 781px;
    }
  }
  
  .ADN-videos {
    width: 900px;
    
    &.videosFormat2 {
      flex-flow: column;
      height: 675px;
    }
  }
  
  .ADN-video {
    height: 300px;
    width: 43%;
  }

  .ADN-video:first-child {
    margin-right: 90px;
  }
  .ADN-video.videosFormat3:first-child, .ADN-video.videosFormat3:nth-child(2) {
    margin-bottom: 70px;
  }
  
  .ADN-video-player-square-left {
    width: 143px;
    height: 143px;
  }
  .ADN-video-player-square-right {
    width: 146px;
    height: 260px;
  }
  .ADN-video.videosFormat3:last-child > .ADN-video-player > .ADN-video-player-square-right {
    top: 49px;
  }
  .ADN-video.videosFormat3:nth-child(2) > .ADN-video-player > .ADN-video-player-square-left {
    top: -20px;
    left: -20px;
    transform: none;
  }
  .ADN-video:last-child > .ADN-video-player > .ADN-video-player-square-left {
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 767px) {
  .ADN {
    height: 855px;

    &.videosFormat2 {
      height: 558px;
    }
  }
  
  .ADN-videos {
    width: 290px;
    flex-direction: column;

    &.videosFormat2 {
      width: 290px;
      height: 592px;
  
      & > .ADN-video {
        width: 100%;
      }
    }
  }
  
  .ADN-video {
    height: 219px;
    width: 100%;
  }
  /* .ADN-video.videosFormat3:last-child {
    display: none;
  } */

  .ADN-video-iframeWrap {
  
    iframe, video {
      height: 164px;
      width: 100%;
    }
  }

  .ADN-video:first-child {
    margin-right: 0px;
  }
  .ADN-video:first-child, .ADN-video:nth-child(2) {
    margin-bottom: 46px;
  }
  .ADN-video:nth-child(2) {
    margin-bottom: 0px;
  }
  .ADN-video.videosFormat3:first-child, .ADN-video.videosFormat3:nth-child(2) {
    margin-bottom: 46px;
  }
  
  .ADN-video-player-square-left {
    display: none;
  }
  .ADN-video-player-square-right {
    display: none;
  }

  .ADN-video:last-child {
    margin-bottom: 30px;
  }
  .ADN-video:last-child > .ADN-video-player {
    margin-bottom: 20px;
  }
} 
