.Player {
  width: 100vw;
  height: 100vh;
  background-color: $blackred;
  position: fixed;
  top: -100vh;
  transition: 0.5s ease-in-out;
  @extend %flex-between-center;
  flex-direction: column;
  z-index: 4;
  padding: 60px 0px;
}

.Player.open {
  top: 0vh;
  transition: 0.5s ease-in-out;
}

.Player-closer-container {
  width: 52px;
  height: 52px;
  position: relative;
  z-index: 4;
  margin-bottom: 30px;
  cursor: pointer;
}

.Player-iframe-container {
  width: 80%;
  height: 80%;
}

.Player-iframe-container iframe {
  width: 100%;
  height: 100%;
}

.ytp-cued-thumbnail-overlay-image {
  background-size: contain;
}

@media screen and (max-width: 767px) {
  .Player {
    padding: 100px 0px;
  }

  .Player-iframe-container {
    width: 90%;
    height: 80%;
  }
}
