.Bieres {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 500px;
}

.BieresMobile {
  display: none;
}

.Bieres-slide {
  opacity: 0;
  transition-duration: 1s ease;
  @extend %flex-center-center;
}

.Bieres-slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  width: 80vw;
  position: relative;
}

.Bieres-slide.active.biereHover {
  height: 508px;
}

.Bieres-slide-name {
  position: absolute;
  top: -200px;
}

.Bieres-slide-containerImage {
  animation: shakeBiere 10s ease-in-out 4s infinite;
  animation: name duration timing-function delay iteration-count direction fill-mode;
}

@keyframes shakeBiere {
  1% {
    transform: rotate(15deg);
  }
  2% {
    transform: rotate(-13deg);
  }
  3% {
    transform: rotate(11deg);
  }
  4% {
    transform: rotate(-9deg);
  }
  5% {
    transform: rotate(7deg);
  }
  6% {
    transform: rotate(-5deg);
  }
  7% {
    transform: rotate(4deg);
  }
  8% {
    transform: rotate(-3deg);
  }
  9% {
    transform: rotate(2deg);
  }
  10% {
    transform: rotate(0deg);
  }
  
}

.Bieres-slide-containerImage.hover {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: none;
}

.Bieres-slide-cardImage.hover {
  transform-style: preserve-3d;
  min-height: 80vh;
  width: 35rem;
  border-radius: 30px;
  padding: 0rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.Bieres-slide-cardImage.hover.biere1 {
  background: radial-gradient(50% 50% at 50% 50%, #AA0E17 21.04%, rgba(170, 14, 23, 0) 50%);
}
.Bieres-slide-cardImage.hover.biere2 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(50, 101, 174, 0.9) 0%, rgba(170, 14, 23, 0) 50%);
}
.Bieres-slide-cardImage.hover.biere3 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(236, 204, 80, 0.9) 0%, rgba(170, 14, 23, 0) 50%);
}
.Bieres-slide-cardImage.hover.biere4 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 64, 0) 0%, rgba(170, 14, 23, 0) 50%);
}
.Bieres-slide-cardImage.hover.biere5 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(45, 208, 61, 0.9) 0%, rgba(170, 14, 23, 0) 50%);
}

.Bieres-slide-imageWrap {
  transform-style: preserve-3d;
  transition: 0.2s ease-in-out transform;
}

.Bieres-slide-image {
  width: 135px;
  height: 506px;
  border-radius: 10px;
  position: relative;
  z-index: 3;
  transition: 0.1s ease-in-out transform;
}

.Bieres-slide-abstraite {
  position: absolute;
  width: 200px;
  height: 200px;
  background-size: cover;
  border-radius: 50%;
  opacity: 0;
}

.Bieres-slide-abstraite1 {
  top: 0px;
  transform: translate(-296px, 40px);
}
.Bieres-slide-abstraite2 {
  top: 0px;
  transform: translate(296px, 70px);
}
.Bieres-slide-abstraite3 {
  bottom: 0;
  transform: translate(-341px, -2px);
}
.Bieres-slide-abstraite4 {
  bottom: 0;
  transform: translate(341px, 29px);
}

.Bieres-slide-abstraite-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(204,34,29, 0.75);
  border-radius: 50%;
}

.Bieres-slide-abstraite-text {
  border-radius: 50%;
  @extend %flex-center-center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Bieres-slide-abstraite-desc {
  margin-top: 10px;
  width: 316px;
}

.Bieres > .Bieres-slide1 > .Bieres-slide-abstraite4 > .Bieres-slide-abstraite-text > .Bieres-slide-abstraite-desc {
  width: 289px;
}

.Bieres-arrow {
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.Bieres-previous-arrow {
  left: 0px;
  top: 50%;
  transform: rotate(180deg) translateY(50%);
}

.Bieres-next-arrow {
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 1600px) {
  .Bieres-slide.active {
    width: 75vw;
    height: 422px;
  }
  .Bieres-slide.active.biereHover {
    height: 422px;
  }

  .Bieres-slide-name {
    top: -156px;
  }
  
  .Bieres-slide-image {
    width: 112.5px;
    height: 422px;
  }
  
  .Bieres-slide-abstraite {
    width: 140px;
    height: 140px;
  }
  
  .Bieres-slide-abstraite1 {
    transform: translate(-207px, 28px);
  }
  .Bieres-slide-abstraite2 {
    transform: translate(207px, 49px);
  }
  .Bieres-slide-abstraite3 {
    transform: translate(-239px, -64px);
  }
  .Bieres-slide-abstraite4 {
    transform: translate(239px, -43px);
  }
  
  .Bieres-slide-abstraite-desc {
    margin-top: 7px;
    width: 221px;
  }
  .Bieres > .Bieres-slide1 > .Bieres-slide-abstraite4 > .Bieres-slide-abstraite-text > .Bieres-slide-abstraite-desc {
    width: 206px;
  }
  
  .Bieres-arrow {
    width: 41px;
    height: 78px;
  }
}

@media screen and (max-width: 1200px) {
  .Bieres {
    margin-top: 370px;
  }
  .Bieres-slide.active {
    width: 90vw;
    height: 340px;
  }
  .Bieres-slide.active.biereHover {
    height: 340px;
  }
  .Bieres > .Bieres-slide1 > .Bieres-slide-abstraite4 > .Bieres-slide-abstraite-text > .Bieres-slide-abstraite-desc {
    width: unset;
  }

  .Bieres-slide-name {
    top: -135px;
  }
  
  .Bieres-slide-image {
    width: 91px;
    height: 340px;
  }
  
  .Bieres-slide-abstraite1 {
    transform: translate(-122px,0px);
  }
  .Bieres-slide-abstraite2 {
    transform: translate(122px,0px);
  }
  .Bieres-slide-abstraite3 {
    transform: translate(-211px,-43px);
  }
  .Bieres-slide-abstraite4 {
    transform: translate(211px,-43px);
  }
  
  .Bieres-slide-abstraite-desc {
    margin-top: 5px;
    width: 166px;
  }
  .Bieres > .Bieres-slide1 > .Bieres-slide-abstraite4 > .Bieres-slide-abstraite-text > .Bieres-slide-abstraite-desc {
    width: 166px;
  }
  
  .Bieres-arrow {
    width: 30px;
    height: 58px;
  }
}

@media screen and (max-width: 767px) {
  .Bieres {
    margin-top: 315px;
  }
  .Bieres-slide-name {
    top: -200px;
  }

  .Bieres-slide-image {
    width: 67px;
    height: 252px;
  }

  .Bieres-slide-abstraite {
    width: 120px;
    height: 120px;
  } 
  .Bieres-slide-abstraite1 {
    transform: translate(-71px,-121px);
  }
  .Bieres-slide-abstraite2 {
    transform: translate(71px,-121px);
  }
  .Bieres-slide-abstraite3 {
    transform: translate(-71px,87px);
  }
  .Bieres-slide-abstraite4 {
    transform: translate(71px,87px);
  }

  .Bieres-slide-abstraite-mask {
    background-color: rgba(204,34,29, 0.6);
  }
  
  .Bieres-slide-abstraite-desc {
    width: 140px;
  }
  .Bieres > .Bieres-slide1 > .Bieres-slide-abstraite4 > .Bieres-slide-abstraite-text > .Bieres-slide-abstraite-desc {
    width: 140px;
  }
  
  .Bieres-arrow {
    width: 30px;
    height: 58px;
  }
}

/* @media screen and (max-width: 1100px) {
  
  .BieresMobile {
    display: none;
    align-items: center;
    justify-self: flex-start;
    flex-direction: column;
    margin: 500px 0px;
  }

  .BieresMobile-biere {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .BieresMobile-biere:not(:last-child) {
    margin-bottom: 100px;
  }
  .BieresMobile-biere:not(:last-child)::after {
    content: '';
    width: 50%;
    height: 2px;
    background-color: $yellow;
    position: absolute;
    bottom: -50px;
  }

  .BieresMobile-biere-image {
    width: 146px;
    margin-top: 28px;
  }

  .BieresMobile-biere-description {
    width: 440px;
  }
  .BieresMobile-biere-description > p:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .BieresMobile {
    margin: 300px 0px;
  }

  .BieresMobile-biere:not(:last-child) {
    margin-bottom: 70px;
  }
  .BieresMobile-biere:not(:last-child)::after {
    bottom: -35px;
  }

  .BieresMobile-biere-image {
    width: 102px;
    margin-top: 20px;
  }

  .BieresMobile-biere-description {
    width: 308px;
  }
  .BieresMobile-biere-description > p:not(:last-child) {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 440px) {
  .BieresMobile-biere-description {
    width: 75vw;
  }
}
 */
